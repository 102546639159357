// services
import { getStoredSelectedModuleItemId } from "@/services/project/projectModuleService";

// model
import { findProjectState } from "@/model/workflow/project/projectModel";

// filters
import { toShortLocalString } from "@/filters/dateFilter";

// vuex
import { createNamespacedHelpers } from "vuex";
const {
  mapState,
  mapMutations,
  mapGetters,
  mapActions
} = createNamespacedHelpers("project");

// store
import { SET_DRAWER_MINI } from "@/store/shared/mutationTypes/record/record-mutation-types";

// mixins
import { moduleItemsDrawerMixin } from "@/mixins/shared/base/drawer/moduleItemsDrawerMixin";
import { projectFilterMixin } from "@/mixins/project/content/projectFilterMixin";

/**
 * projectDrawerMixin
 */
export const projectDrawerMixin = {
  mixins: [moduleItemsDrawerMixin, projectFilterMixin],
  computed: {
    ...mapState({ projects: "projects" }),

    /**
     * Overwrite moduleItemsDrawerMixin Getters
     */
    ...mapGetters(["drawerMini", "selectedModuleItemId"]),

    /**
     * Overwrite (projects) items
     * @return {{projectId: Number, creationDate: string, definitionId: Number, workflowInstanceId: Number, workflowTypeId: Number, projectName: String, recordId: Number, subject: String, owner: String, projectManagerId: Number, templateId: Number, templateName: String, workflowInstanceId: number, status: number, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean}[]}
     */
    items() {
      let filteredItems = this.projects;

      // Filter by name
      if (this.searchDrawerItemName) {
        filteredItems = filteredItems.filter(el => {
          return el.projectName
            .toLowerCase()
            .startsWith(this.searchDrawerItemName.toLowerCase());
        });
      }

      // Filter by selected statuses
      if (this.selectedFilterOption.length > 0) {
        this.selectAllProjects = false; //Reset Checkbox for Report
        filteredItems = filteredItems.filter(el => {
          return this.selectedFilterOption.includes(
            findProjectState(el.status)?.name
          );
        });
      }

      return filteredItems;
    },

    /**
     * Overwrite suggested Select (Project) Module Item id
     * @return {Number|number}
     */
    defaultSelectModuleItemId() {
      return this.itemCount > 0 ? this.items[0].projectId : -1;
    }
  },
  methods: {
    ...mapActions({ setProjects: "setProjects" }),

    /**
     * Overwrite moduleItemsDrawerMixin Mutations
     */
    ...mapMutations({ setDrawerMini: SET_DRAWER_MINI }),

    /**
     * Get Stored Selected Module Item Id (projectId Id)
     * Overwrites moduleItemsDrawerMixin.getStoredSelectedModuleItemId
     * @returns {Number|number}
     */
    getStoredSelectedModuleItemId: () => getStoredSelectedModuleItemId(),

    /**
     * Overwrite Determines whether Module (Project) Item exists by its id
     * @param {Number|number} id
     * @return {Boolean|boolean}
     */
    existsModuleItem(id) {
      return this.items?.find(el => el.projectId === id) ?? undefined;
    },

    /**
     * Load, set and list Workflow Projects
     * @returns {void}
     */
    async listItems() {
      try {
        this.clearError();
        this.isLoading = true;
        await this.setProjects();
        await this.ensureSelectedItem();
      } catch (e) {
        this.handleError(e);
      } finally {
        this.isLoading = false;
      }
    },

    /**
     * display Project Date
     * @param {{projectId: Number, creationDate: string, definitionId: Number, workflowInstanceId: Number, workflowTypeId: Number, projectName: String, recordId: Number, subject: String, owner: String, projectManagerId: Number, templateId: Number, templateName: String, workflowInstanceId: number, status: number, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean}} project Project
     * @return {string|string}
     */
    displayProjectDate(project) {
      return project?.creationDate
        ? toShortLocalString(project.creationDate)
        : "";
    }
  }
};
