<template>
  <core-navigation-drawer
    :drawer-mini="drawerMini"
    :error="currentError"
    @alertVisibilityChanged="onAlertVisibilityChanged"
    @toggleMiniDrawer="toggleMiniDrawer"
  >
    <template v-slot:content>
      <base-nav-list>
        <!-- Search By Project Name -->
        <v-list-item dense>
          <search-text-field
            :loading="isLoading"
            v-model="searchDrawerItemName"
          ></search-text-field>

          <!-- Filter by Project Status -->
          <project-filter-menu @optionChanged="filterOptionChanged">
          </project-filter-menu>

          <!-- Select All Projects Checkbox -->
          <v-simple-checkbox
            hide-details
            class="mt-0"
            v-model="selectAllProjects"
            :indeterminate="indeterminateState"
          ></v-simple-checkbox>
        </v-list-item>
      </base-nav-list>

      <!-- List Projects -->
      <project-list
        :projects="items"
        :select-all-projects="selectAllProjects"
        @itemSelected="onItemSelected"
      ></project-list>

      <!-- Show Report Dialog -->
      <report-dialog
        :record-list="selectedProjects"
        :search-headers="reportHeaders"
        :visible="visibleReportDialog"
        :report-module="moduleName.Projects"
        @close="visibleReportDialog = false"
      >
      </report-dialog>
    </template>

    <template v-slot:append>
      <v-divider dark></v-divider>

      <!-- Record Nodes / Display Range -->
      <v-row v-if="selectedProjects.length" class="mt-1">
        <v-col cols="12" md="6" class="mt-2">
          <base-button @click="exportProjects()">
            <template v-slot:icon-name>
              {{ iconExport }}
            </template>
            <template v-slot:default> &nbsp; {{ commandExport }} </template>
          </base-button>
        </v-col>
      </v-row>
    </template>
  </core-navigation-drawer>
</template>

<script>
import { projectDrawerMixin } from "@/mixins/project/drawer/projectDrawerMixin";

export default {
  name: "ProjectDrawer",
  mixins: [projectDrawerMixin],
  components: {
    ProjectList: () => import("@/components/project/ProjectList")
  },
  async created() {
    try {
      await this.listItems();
    } catch (e) {
      this.handleError(e);
    }
  }
};
</script>
