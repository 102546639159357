import { setLocalStorageItem } from "@/utils/localStorageUtility";
import {
  projectHeaders,
  projectLocalStorageKeys
} from "@/model/workflow/project/projectModel";

/**
 * Project Filter Mixin
 */
export const projectFilterMixin = {
  components: {
    ReportDialog: () => import("@/components/shared/core/dialogs/ReportDialog"),
    ProjectFilterMenu: () =>
      import("@/components/shared/core/menu/ProjectFilterMenu")
  },
  data() {
    return {
      selectedProjects: [],
      reportHeaders: projectHeaders,
      visibleReportDialog: false,
      selectAllProjects: false,
      selectedFilterOption: [],
      indeterminateState: false
    };
  },
  computed: {
    /**
     * Export Command Text
     * @return {string}
     */
    commandExport() {
      return "Export";
    }
  },
  methods: {
    /**
     * Event On Projects Selected
     * @param payload
     */
    onItemSelected(payload) {
      this.selectedProjects = payload.projects;

      this.indeterminateState = payload.setIndeterminate;

      if (this.selectedProjects.length === this.items.length) {
        this.selectAllProjects = true;
        this.indeterminateState = false;
      }

      if (this.selectedProjects.length === 0) {
        this.selectAllProjects = false;
        this.indeterminateState = false;
      }
    },

    /**
     * Export Project Action
     */
    exportProjects() {
      this.visibleReportDialog = true;
    },

    /**
     * Event Project Filter by Status Changed
     * @param option
     */
    filterOptionChanged(option) {
      this.selectedFilterOption = option;
      setLocalStorageItem(
        projectLocalStorageKeys.projectFilterOption,
        JSON.stringify(this.selectedFilterOption)
      );
    }
  }
};
